import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

const Greeting = () => (
    <Layout>
        <SEO title='Konnichiwa' />
        <h2 style={{ margin: '1em 0' }}>挨拶</h2>

    </Layout>
)

export default Greeting